import '@commons/polyfills';
import '@commons/reset';
import '@commons/general';
import '@commons/header';
import '@commons/utils';

// Home specific styles
import '@styles/components/c-trustpilot.scss';
import '@styles/components/c-featured-posts.scss';
import '@styles/components/c-mosaic.scss';
import '@styles/components/c-product-notification-tiles.scss';
import '@styles/components/c-home-hero.scss';
import '@styles/components/c-testimonials.scss';
import '@styles/components/c-image-call-to-action.scss';
import '@styles/components/c-rounded-grid.scss';
import '@styles/layouts/l-instagram-feed.scss';

import featuredCaseStudiesCarousel from '@modules/featuredCaseStudiesCarousel';

export default function init() {
  featuredCaseStudiesCarousel();
}

init();
